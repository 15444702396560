define('ingenio-web/mixins/checkout-flow/confirmation/controller', ['exports', 'ingenio-web/utils/logger', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/scroll-helpers', 'ingenio-web/mixins/checkout-flow/controller-base', 'ingenio-web/mixins/checkout-flow/quick-start-handler', 'ingenio-web/mixins/checkout-flow/payment-details/offerRemovalHelper'], function (exports, _logger, _errorHandler, _transitionHelpers, _scrollHelpers, _controllerBase, _quickStartHandler, _offerRemovalHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controllerBase.default, _errorHandler.default, _transitionHelpers.default, _scrollHelpers.default, _quickStartHandler.default, _offerRemovalHelper.default, {
        queryParams: ['listingid', 'pmode', 'webtrack', 'arrange', 'r', 'arrangeid'],
        pmode: 0,
        webtrack: 1,
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        r: true, //we use in for redirect from confirmation to details without back redirect in case of enough money
        iovationClient: Ember.inject.service('iovation'),
        checkoutRiskClient: Ember.inject.service('checkout-risk'),
        userEvents: Ember.inject.service(),

        primaryButtonBusy: false,

        _showConfirmDepositDialog: false,

        displayConfirmDepositDialog: Ember.computed.equal('_showConfirmDepositDialog', true),

        //we use it to avoid extra complexity in template near CTA button.
        showLockOnButton: Ember.computed('model.customerWillBeCharged', 'primaryButtonBusy', function () {
            var customerWillBeCharged = this.get('model.customerWillBeCharged');
            var primaryButtonBusy = this.get('primaryButtonBusy');

            return customerWillBeCharged && !primaryButtonBusy;
        }),

        primaryButtonBusyQS: Ember.computed('quickStartFlow', 'primaryButtonBusy', function () {
            var quickStartFlow = this.get('quickStartFlow');

            if (quickStartFlow) {
                return true;
            }

            var primaryButtonBusy = this.get('primaryButtonBusy');

            return primaryButtonBusy;
        }),

        isChat: Ember.computed('target.currentPath', function () {
            var currentPath = this.get('target.currentPath').toLowerCase();

            return currentPath.startsWith('chat-setup');
        }),

        isAddFunds: Ember.computed('target.currentPath', function () {
            var currentPath = this.get('target.currentPath').toLowerCase();

            return currentPath.startsWith('add-funds');
        }),

        isRedeemOffer: Ember.computed('target.currentPath', function () {
            var currentPath = this.get('target.currentPath').toLowerCase();

            return currentPath.startsWith('redeem-offer');
        }),

        conversationSetup: Ember.computed('target.currentPath', function () {
            var currentPath = this.get('target.currentPath').toLowerCase();

            return currentPath.startsWith('chat-setup') || currentPath.startsWith('call-setup');
        }),

        initialize: function initialize() {
            this.set('logger', new _logger.default(this.store, {
                source: this.get('log_source'),
                consumerId: Ember.Application.user.id
            }));

            if (this.get('quickStartFlow')) {
                this.initializeQuickStartFlow();
            }
            this.get('checkoutRiskClient').getDeviceId();
        },


        actions: {
            onChangeFundsClick: function onChangeFundsClick() {
                this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.details', { r: true });
            },
            changePaymentMethod: function changePaymentMethod() {
                this.transitionToPaymentDetails();
            },
            cancelConfirmDeposit: function cancelConfirmDeposit(creditCard) {
                this.set('isBusy', false);
                this.set('_showConfirmDepositDialog', false);
                creditCard.clearTouched();
                creditCard.removeErrors();
                this.set('avsModel.cvvCode', '');
                creditCard.set('recheckAVS', true); // required for handling avs modal cancel button click.
            },
            avscvnContinueConfirmDeposit: function avscvnContinueConfirmDeposit(creditCard) {
                var _this = this;

                this.set('model.paymentMethodSelected.cvvCode', this.get('avsModel.cvvCode'));
                this.set('model.user.funds.cvvCode', this.get('avsModel.cvvCode'));
                this.set('model.paymentMethodSelected.address', this.get('avsModel.address'));

                if (creditCard.get('isAvsRequired')) {
                    // we need to update card details (address, name, etc)
                    // if AVS is required

                    // set flag that card is under processing
                    creditCard.set('inProcess', true);
                    // remove previous server side errors
                    creditCard.clearGenericErrors();

                    creditCard.save().then(function () {
                        // reset indicator
                        creditCard.set('inProcess', false);

                        _this.set('_showConfirmDepositDialog', false);
                        _this.send('onPrimaryButtonClick');
                    }, function (error) {
                        creditCard.handleError(error);
                    });
                } else {
                    // submit CVN code to add money function
                    this.set('_showConfirmDepositDialog', false);
                    this.send('onPrimaryButtonClick');
                }
            }
        },

        validateOffer: function validateOffer() {
            var _this2 = this;

            return this.removeOfferIfNotQualified(this.get('model.user.offer.id'), this.get('logger')).then(function (offerStatus) {
                return _this2.onNonQualifiedOfferRemoval(offerStatus, _this2.get('model'), _this2.get('logger'), _this2.get('mixpanelClient'));
            }).catch(function (error) {
                _this2.get('logger').error('Problem in removeOfferIfNotQualified(). userId=' + Ember.Application.user.id, {
                    originalError: error
                });
            });
        },
        createAdditionalFundsInfo: function createAdditionalFundsInfo() {
            var selectedBox = this.get('model.user.selectedBox');
            var priceBuckets = this.get('model.user.priceBuckets');
            var selectedPriceBuckets = priceBuckets ? priceBuckets.findBy('selected', true) : null;
            var manualInput = !selectedPriceBuckets || selectedBox === '$custom';

            var priceBucketMinutes = !manualInput ? selectedPriceBuckets.get('minuteQuantity') : null;

            var isChat = this.get('isChat');

            var additionalFundsInfo = this.store.createFragment('additionalFundsInfo', {
                priceBucketMinutes: priceBucketMinutes,
                source: isChat ? 'chat_setup' : 'call_setup',
                rechargeMethod: manualInput ? 'ManualInput' : 'PriceBucketMinutes' //ManualInput, PriceBucket, PostPay
            });

            return additionalFundsInfo;
        },
        createAdditionalFundsInfoNonConversationFlow: function createAdditionalFundsInfoNonConversationFlow() {
            var source = '';

            if (this.get('isAddFunds')) {
                var returnUrl = this.get('model.returnUrl') || '';

                if (returnUrl) {
                    returnUrl = returnUrl.toLowerCase();
                }

                if (returnUrl.indexOf('payment-info') >= 0) {
                    source = 'add_funds_payment_info';
                } else if (returnUrl.indexOf('myaccount') >= 0) {
                    source = 'add_funds_myaccount';
                } else {
                    source = 'add_funds_unknown';
                }
            } else if (this.get('isRedeemOffer')) {
                source = 'redeem_offer';
            } else {
                source = 'unknown'; //TODO: check how can we get adding funds with adding new paypal account
            }

            var additionalFundsInfo = this.store.createFragment('additionalFundsInfo', {
                priceBucketMinutes: null,
                source: source,
                rechargeMethod: 'ManualInput'
            });

            return additionalFundsInfo;
        },
        addFundsToAccount: function addFundsToAccount() {
            var _this3 = this;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                // don't need to add money if user didn't select amount to add or selected pay after chat option
                if (!_this3.get('model.user.funds.amountAsNumber') || _this3.get('model.payAfterConversationSelected') || _this3.get('model.autoReloadSelected')) {
                    resolve(null);
                } else {
                    _this3.get('model.user.funds').setProperties({
                        eventSource: 'WebSite',
                        deviceId: _this3.get('iovationClient').getDeviceId(),
                        checkoutComDeviceId: _this3.get('checkoutRiskClient').getDeviceId(),
                        additionalFundsInfo: _this3.get('conversationSetup') ? _this3.createAdditionalFundsInfo() : _this3.createAdditionalFundsInfoNonConversationFlow(),
                        offerDisplayAmount: _this3.getOfferDisplayAmount()
                    });
                    _this3.get('model.user.funds').save().then(function () {
                        // NOTE: when refreshing the balance, the talk time in the order summary will
                        // reflect any changes to the user's free available minutes and reflect in real time.
                        // the talk time calculation adds the offer reward minutes and the unrewarded free
                        // minutes. if the offer model is not refreshed, it is possible the offer price
                        // (if already associated and part of the balance) will be added twice and needs
                        // to be handled independently.
                        _this3.store.findRecord('balance', Ember.Application.user.id, {
                            adapterOptions: {
                                listingId: _this3.get('model.listing.id')
                            }
                        }).then(function (balance) {
                            _this3.getCurrentSpecialOffer(_this3.get('model')).finally(function () {
                                _this3.set('model.user.balance', balance);

                                // the current "add funds" api does not include a designated primary key and
                                // within Ember we hard-code the identity value to the user's id. unloading the funds
                                // model(s) from the store fixes a primary key collision error between add funds operations.
                                _this3.store.unloadAll('funds');

                                var newFundsModel = _this3.store.createRecord('funds');
                                var currentFundsModel = _this3.get('model.user.funds');

                                newFundsModel.set('cvvCode', currentFundsModel.get('cvvCode'));
                                newFundsModel.set('paymentMethodId', currentFundsModel.get('paymentMethodId'));

                                _this3.set('model.user.funds', newFundsModel);

                                resolve(balance);
                            });
                        });
                        _this3.get('userEvents').fundsAdded('Purchase');
                    }).catch(function (error) {
                        return reject(error);
                    });
                }
            });
        },
        showConfirmDepositDialog: function showConfirmDepositDialog(isAvsReqd) {
            var _this4 = this;

            var selectedCreditCard = this.get('model.paymentMethodSelected');

            // get credit card and show dialog to provide new address or cvv code

            this.get('store').findRecord('creditCard', selectedCreditCard.id).then(function (entity) {
                //selectedCreditCard.set('address', entity.get('address'));
                entity.set('isAvsRequired', isAvsReqd);
                entity.set('isCvnRequired', true);

                // validate credit card with newly assigned attributes
                entity.validate();

                _this4.set('avsModel', entity);
                _this4.set('_showConfirmDepositDialog', true);
            });
        },
        transitionToPaymentDetails: function transitionToPaymentDetails() {
            this.transitionToRouteWithCurrentParams(this.getParentRouteName() + '.payment-details');

            this.scrollToElementOnMobileViewWithDelay('.payment-details-container');
        },


        //TODO : temporary fix for international currency test
        //Should be removed once international currency is fully supported.
        getOfferDisplayAmount: function getOfferDisplayAmount() {
            var currency = Ember.Application.user.preferredCurrency;
            var offerDesc = this.get('model.offerDescription');
            if (currency != '$' && offerDesc) {
                var offerDisplayAmount = offerDesc.split(currency).pop();
                if (!isNaN(offerDisplayAmount)) {
                    return offerDisplayAmount;
                }
            }
            return 0;
        }
    });
});