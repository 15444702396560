define('ingenio-web/components/chat/engagement-text', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var keenSentences = ['TIP: Take a moment to jot down your thoughts while waiting for your advisor.', 'REM: You are in good hands and your future is bright.', 'DYK: Keen offers advice in over 20 different categories, including love, life questions, tarot, and more.', 'DYK: Keen can help you find your perfect advisor. Try Best Match from your My Keen page!', 'TIP: Use Ping to reach out to your advisor and schedule your next reading!', 'TIP: Write down what questions you want to ask your advisor.', 'TIP: Keeping an open mind and heart deepens the psychic connection.', 'TIP: Ask direct, clear questions for a better reading.', 'TIP: Take the time to introduce yourself to your advisor.', 'TIP: Review your chat transcripts to reflect on your reading.', 'REM: You can "favorite" advisors you enjoyed speaking with.', 'DYK: Love is the most popular psychic topic. Ask about your love life!', 'DYK: Psychics have many different specialties, like clairvoyance or mediumship.', 'DYK: Psychics can give helpful guidance about your career.', 'DYK: Psychic readings can help you better understand the people in your life.', 'DYK: An advisor can reveal the different influences in your life.', 'DYK: Psychic advisors make great spiritual guides.', 'TIP: Try different types of readings, such as tarot, dream interpreation, and more.', 'TIP: Is Spanish your primary language? Check out our "Psíquicos" category.', 'TIP: Take a deep breath to clear your mind.', 'TIP: Check in with your advisor regularly for new updates about your situation.', 'TIP: The future has many possibilities. Your advisor can help you explore them all.', 'TIP: An advisor can reveal likely outcomes, but the future is in your hands.', 'TIP: Meditating gives you a clear mind, which helps advisors connect to your energy.'];

    var pcSentences = ['TIP: Write down questions for your psychic in advance.', 'TIP: Sharing your first name and birthday helps your psychic connect to your energy', 'TIP: Remember, the future is always in your hands.', 'TIP: Make sure to "favorite" your go-to psychics.', 'TIP: Take a deep breath and connect to your inner truth.', 'TIP: Bring positivity into the chat as it will effect the energy of the reading.', 'TIP: Meditating before or after readings can help you absorb your psychic\'s advice', 'TIP: Ask open-ended questions so your psychic can paint a full picture.', 'TIP: Reflect on your psychic\'s guidance by reading your chat transcripts.', 'TIP: Visit our Psychic Wiki to learn more about all things psychics.', 'TIP: Learn more psychic terms by visiting our Psychic Glossary.', 'TIP: Ask your psychic about your spirit guides.', 'TIP: Ask your psychic about anything, such as love, career, and more.', 'TIP: Try different reading styles, such as tarot, mediumship, or astrology.', 'TIP: Get readings regularly to deepen your relationship with your psychic.', 'TIP: Maintain your inner balance with spiritual guidance from a psychic.', 'TIP: In times of uncertainty, psychics can help bring reassurance.'];

    exports.default = Ember.Component.extend({
        init: function init() {
            this._super.apply(this, arguments);

            if (Ember.ENV.domainName === 'Keen') {
                this.sentences = keenSentences;
            } else {
                this.sentences = pcSentences;
            }
        },

        sentences: null,

        displayContent: '<div class="fadeInUp animated tip-wrapper"><div><strong>Please note ☝</strong></div> Moving to another screen may end your chat.</div>',

        getRandomSentence: function getRandomSentence() {
            var sentences = this.get('sentences');
            var index = Math.floor(Math.random() * sentences.length);
            var statement = sentences[index];

            return statement;
        },
        didInsertElement: function didInsertElement() {
            var self = this;
            // rotate text every 4 seconds
            var timer = setInterval(function () {
                var text = self.getRandomSentence() || '';
                var domainPrefix = Ember.ENV.domainName === 'Keen' ? 'Keen ' : '';

                var html = text.replace('TIP:', '<div><strong>' + domainPrefix + ' Tip \uD83D\uDCA1</strong></div>').replace('REM:', '<div><strong>Remember ☝</strong></div>').replace('DYK:', '<div><strong>Did You Know ❓</strong></div>');

                self.set('displayContent', '<div class="fadeInUp animated tip-wrapper"><div class="tip-content">' + html + '</div></div>');
            }, 6000);

            this.set('timer', timer);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);

            if (this.get('timer')) {
                clearInterval(this.get('timer'));
            }
        },


        actions: {}
    });
});