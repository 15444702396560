define('ingenio-web/mixins/checkout-flow/payment-details/controller', ['exports', 'ingenio-web/utils/url', 'ingenio-web/utils/logger', 'ingenio-web/mixins/error-handler', 'ingenio-web/mixins/transition-helpers', 'ingenio-web/mixins/checkout-flow/controller-base', 'ingenio-web/mixins/checkout-flow/payment-details/offerRemovalHelper', 'ingenio-web/utils/cookies', 'ingenio-web/mixins/checkout-flow/chat-availability-helper'], function (exports, _url, _logger, _errorHandler, _transitionHelpers, _controllerBase, _offerRemovalHelper, _cookies, _chatAvailabilityHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var PaymentMode = Object.freeze({
        NewPaypal: 'new-paypal',
        ListPayment: 'list-payment',
        NewCreditCard: 'new-credit-card',
        EditCreditCard: 'edit-credit-card'
    });

    exports.default = Ember.Controller.extend(_controllerBase.default, _errorHandler.default, _transitionHelpers.default, _offerRemovalHelper.default, _chatAvailabilityHelper.default, {
        userEvents: Ember.inject.service(),
        iovationClient: Ember.inject.service('iovation'),
        checkoutRiskClient: Ember.inject.service('checkout-risk'),
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        queryParams: ['listingid', 'pmode', 'webtrack', 'arrange', 'arrangeid'],

        pmode: 0,
        webtrack: 1,

        creditCard: null,
        isCreditCardValid: false,
        primaryButtonBusy: false,
        enablePrimaryButton: false,

        primaryButtonText: Ember.computed('paymentMode', function () {
            switch (this.get('paymentMode')) {
                case PaymentMode.ListPayment:
                    return 'Confirm';

                case PaymentMode.NewPaypal:
                    return 'Link PayPal';

                case PaymentMode.NewCreditCard:
                    return 'Add Card';

                case PaymentMode.EditCreditCard:
                    return 'Update Card';
            }
        }),

        showWhyAddPaymentText: Ember.computed('model.user.funds.amountAsNumber', function () {
            return this.get('model.user.funds.amountAsNumber') === 0;
        }),

        initialize: function initialize() {
            this.get('checkoutRiskClient').getDeviceId();
            if (this.get('model.hasExistingPaymentMethods')) {
                this.set('creditCard', null);
                this.set('paymentMode', PaymentMode.ListPayment);
            } else {
                this.set('paymentMode', PaymentMode.NewCreditCard);
                this.set('creditCard', this.getNewCreditCard());
            }

            this.set('enablePrimaryButton', this.get('model.user.funds.paymentMethodId') !== null);

            //TODO: Muthu. Refactor logger util to be independent of ember data store
            this.set('logger', new _logger.default(this.store, {
                source: 'payment-details',
                consumerId: Ember.Application.user.id
            }));
        },
        inDashboardMode: function inDashboardMode() {
            var dashboardToolMode = _cookies.default.getDashboardMode();
            var isCloverFlow = _cookies.default.isCloverFlow();

            var showError = dashboardToolMode === '1' && !isCloverFlow;

            if (showError) {
                this.clearErrors();

                this.addError(null, 'Sorry, you are not allowed to perform this action.');
                this.displayFlashMessage();

                this.set('primaryButtonBusy', false);
                this.set('enablePrimaryButton', true);

                return true;
            }

            return false;
        },


        actions: {
            onPrimaryButtonClick: function onPrimaryButtonClick() {
                var _this = this;

                var self = this;
                this.set('enablePrimaryButton', false);

                switch (this.get('paymentMode')) {
                    case PaymentMode.NewPaypal:
                        if (this.inDashboardMode()) {
                            return;
                        }
                        this.set('primaryButtonBusy', true);
                        this.redirectToPaypal();
                        break;

                    case PaymentMode.NewCreditCard:
                        if (this.inDashboardMode()) {
                            return;
                        }
                        this.set('primaryButtonBusy', true);
                        this.clearErrors();

                        var cardModel = this.get('creditCard');
                        var name = cardModel.get('nameOnAccount');
                        var country = cardModel.get('address.countryCode');
                        var zip = cardModel.get('address.postalCode');

                        if (Frames.isCardValid()) {

                            // Add cardholder information.
                            Frames.cardholder = {
                                name: name,
                                billingAddress: {
                                    country: country,
                                    zip: zip
                                }
                            };

                            Frames.submitCard().then(function (data) {
                                self.saveCreditCard(data).catch(function (error) {
                                    // TODO: after normalizing the api error structure... this can be disregarded.
                                    if (error && !Ember.isEmpty(Ember.get(error, 'errors.generic-error'))) {
                                        Ember.set(error, 'errors.generic', Ember.get(error, 'errors.generic-error'));
                                    }

                                    self.get('logger').error('Problem in saving user\'s credit card information. userId=' + Ember.Application.user.id, {
                                        originalError: error
                                    });

                                    self.get('checkoutRiskClient').reinitializeFrames();

                                    self.handleError(error);
                                }).finally(function () {
                                    self.set('primaryButtonBusy', false);
                                    self.set('enablePrimaryButton', true);
                                });
                            }).catch(function (error) {
                                // handle error
                                console.log('Error tokenizing card', error);
                            });
                        } else {
                            console.log('card is not valid');
                        }

                        break;

                    case PaymentMode.EditCreditCard:
                        if (this.inDashboardMode()) {
                            return;
                        }
                        this.set('primaryButtonBusy', true);
                        this.clearErrors();

                        this.updateCreditCard().then(function () {
                            _this.set('paymentMode', PaymentMode.ListPayment);
                            window.location.reload();
                        }).catch(function (error) {
                            _this.get('logger').error('Problem in Updating user\'s credit card information. userId=' + Ember.Application.user.id, {
                                originalError: error
                            });
                            _this.handleError(error);
                        }).finally(function () {
                            _this.set('primaryButtonBusy', false);
                            _this.set('enablePrimaryButton', true);
                        });
                        break;

                    case PaymentMode.ListPayment:
                        this.confirmPaymentMethod();
                        break;
                }
            },
            onPaymentModeChanged: function onPaymentModeChanged(mode, data) {
                var _this2 = this;

                this.set('enablePrimaryButton', false); // reset flag & conditionally re-enable below as necessary.
                this.clearErrors();

                switch (mode) {
                    case PaymentMode.NewCreditCard:
                        // the credit card data should not be cleared when the user is toggling between the CC and PayPal
                        // tabs (only visible when the user does not have an existing payment method). In all other cases,
                        // the credit card model should be reset.
                        if (Ember.isEmpty(this.get('creditCard')) || this.get('model.hasExistingPaymentMethods')) {
                            this.set('creditCard', this.getNewCreditCard());
                        }

                        this.set('paymentMode', PaymentMode.NewCreditCard);
                        break;

                    case PaymentMode.EditCreditCard:
                        this.set('primaryButtonBusy', true);
                        this.getExistingCreditCard(data.paymentMethodId).then(function (creditCard) {
                            return _this2.set('creditCard', creditCard);
                        }).finally(function () {
                            _this2.set('primaryButtonBusy', false);
                            _this2.set('paymentMode', PaymentMode.EditCreditCard);
                        });
                        break;

                    case PaymentMode.NewPaypal:
                        this.set('enablePrimaryButton', true);
                        this.set('paymentMode', PaymentMode.NewPaypal);
                        break;

                    default:
                    case PaymentMode.ListPayment:
                        this.set('creditCard', null);
                        this.set('paymentMode', PaymentMode.ListPayment);

                        // enable primary CTA if there is a payment method selected
                        this.set('enablePrimaryButton', !Ember.isEmpty(this.get('model.user.funds.paymentMethodId')));
                        break;
                }
            },
            onPaymentMethodChosen: function onPaymentMethodChosen(paymentMethodId, setSelectedPaymentAsDefault) {
                // The current APIs do not support changing the primary payment method
                // for existing methods. This should be implemented once the api is refactored.
                // this.set('setSelectedPaymentAsDefault', setSelectedPaymentAsDefault);

                this.set('enablePrimaryButton', true);
                this.set('model.user.funds.paymentMethodId', paymentMethodId);
            },
            onCreditCardValidityChanged: function onCreditCardValidityChanged(isCreditCardValid) {
                this.set('isCreditCardValid', isCreditCardValid);
                this.set('enablePrimaryButton', isCreditCardValid);
            },
            onCreditCardChanged: function onCreditCardChanged(creditCardData) {
                this.get('creditCard').setProperties({
                    cvvCode: creditCardData.cvv,
                    expiration: creditCardData.expiration,
                    accountNumber: (creditCardData.cardNumber || '').replace(/\s/g, ''),
                    nameOnAccount: creditCardData.nameOnCard,
                    creditCardType: creditCardData.creditCardType || this.get('creditCard.creditCardType'),
                    address: {
                        countryCode: creditCardData.country,
                        postalCode: creditCardData.postalCode
                    }
                });
            },
            onAddPaypal: function onAddPaypal() {
                //This action is just used for the "Add Paypal" link which is appearing on the payment list component
                //Another way to avoid this action is to create a Paypal service which will have the redirectToPaypal() as part of it and can be shared between route and the component
                this.redirectToPaypal();
            }
        },

        getNewCreditCard: function getNewCreditCard() {
            var creditCard = this.store.createRecord('creditCardNew');
            creditCard.set('address', this.store.createRecord('address'));

            return creditCard;
        },
        getExistingCreditCard: function getExistingCreditCard(paymentMethodId) {
            var _this3 = this;

            // refresh local data store cache to fix a bug where when editing a card, clicking on "cancel",
            // and then editing another card the mutated data was not reset.
            this.store.unloadAll('creditCardNew');

            return this.store.findRecord('creditCardNew', paymentMethodId, {
                reload: true
            }).catch(function (error) {
                return _this3.handleError(error);
            });
        },
        saveCreditCard: function saveCreditCard(data) {
            var _this4 = this;

            this.get('creditCard').setProperties({
                eventSource: 'WebSite',
                token: data.token,
                checkoutComToken: data,
                deviceId: this.get('iovationClient').getDeviceId(),
                checkoutComDeviceId: this.get('checkoutRiskClient').getDeviceId()
            });

            return this.get('creditCard').save().then(function (newCard) {
                return _this4.onCreditCardSave(newCard).then(function (returnToStep) {
                    return _this4.refreshPaymentMethodsInStore().then(function (paymentMethods) {
                        _this4.verifyCreditCardSave(paymentMethods, newCard);

                        if (returnToStep === 'return-to-details-step') {
                            _this4.send('onUserOfferDeleted');
                            _this4.transitionToRouteWithCurrentParams(_this4.getParentRouteName() + '.details');
                        } else {
                            _this4.transitionToRouteWithCurrentParams(_this4.getNextRouteName());
                        }
                    });
                });
            });
        },
        updateCreditCard: function updateCreditCard() {
            var _this5 = this;

            this.get('creditCard').setProperties({
                eventSource: 'WebSite',
                deviceId: this.get('iovationClient').getDeviceId()
            });

            return this.get('creditCard').save().then(function (updatedCard) {
                var creditCardId = updatedCard.get('id');
                var expiration = updatedCard.get('expiration');

                _this5.set('model.user.funds.paymentMethodId', creditCardId);
                _this5.get('model.user.paymentMethods').findBy('id', creditCardId).set('expiration', expiration);
            });
        },


        // 1) Generate paypal express-checkout token
        // 2) Redirect to paypal express-checkout url
        redirectToPaypal: function redirectToPaypal() {
            var _this6 = this;

            if (this.inDashboardMode()) {
                return;
            }

            var paypalToken = this.store.createRecord('paypalToken', {
                userId: Ember.Application.user.id,
                returnUrl: this.getScopedUrlForPaypal(true),
                cancelUrl: this.getScopedUrlForPaypal()
            });

            paypalToken.save().then(function (tokenResponse) {
                return window.location.href = Ember.ENV.paypal.expressCheckoutUrl + '&token=' + tokenResponse.get('token');
            }, function (error) {
                return _this6.handleError(error);
            });
        },
        getScopedUrlForPaypal: function getScopedUrlForPaypal(shouldProcessToken) {
            var url = window.location.href;

            var amountToCharge = this.get('model.amountToCharge');

            if (shouldProcessToken) {
                url = _url.default.appendQueryParamToUrl(url, {
                    key: 'processPaypalToken',
                    value: true
                });
            }

            if (amountToCharge) {
                url = _url.default.appendQueryParamToUrl(url, {
                    key: 'addamt',
                    value: amountToCharge
                });
            }

            return url;
        },
        getNextRouteName: function getNextRouteName() {
            return this.getParentRouteName() + '.confirmation';
        },
        confirmPaymentMethod: function confirmPaymentMethod() {
            var _this7 = this;

            var transfer = function transfer() {
                _this7.transitionToRouteWithCurrentParams(_this7.getNextRouteName());
            };

            var paymentMethodSelected = this.get('model.paymentMethodSelected');

            if (!paymentMethodSelected.get('isPrimary')) {
                this.get('model.user.paymentMethods').setEach('isPrimary', false);
                this.get('model.paymentMethodSelected').set('isPrimary', true);

                return this.get('model.paymentMethodSelected').save().then(function () {
                    transfer();
                });
            } else {
                transfer();
            }
        },
        refreshPaymentMethodsInStore: function refreshPaymentMethodsInStore() {
            var _this8 = this;

            return this.store.findAll('paymentMethod', {
                reload: true
            }).then(function (paymentMethods) {
                _this8.set('model.user.paymentMethods', paymentMethods);

                return paymentMethods;
            });
        },
        onCreditCardSave: function onCreditCardSave(newCard) {
            var _this9 = this;

            this.set('model.user.funds.paymentMethodId', newCard.get('id'));

            this.get('userEvents').paymentMethodAdded('creditCard');

            var advisorId = this.get('model.listing.advisorId');
            var listingId = this.get('model.listing.id');

            var chatFlow = this.target.currentRouteName === 'chat-setup.payment-details';

            if (chatFlow) {
                this.checkChatAvailability(advisorId).then(function (result) {
                    _this9.get('mixpanelClient').trackNewEvent('Chat Collision During Payment (Web-Only)', {
                        eventData: {
                            advisor_id: advisorId,
                            chat_status: result.chatAvailability,
                            listing_id: listingId
                        }
                    });
                });
            }

            /*
            At this point the offer qualification is checked for non-qualification and is deleted
            If the user has sufficient balance proceed to the confirmation step
            Else redirect the user to the add funds step
            */
            return this.removeOfferIfNotQualified(this.get('model.user.offer.id'), this.get('logger')).then(function (offerStatus) {
                return _this9.onNonQualifiedOfferRemoval(offerStatus, _this9.get('model'), _this9.get('logger'), _this9.get('mixpanelClient'));
            }).catch(function (error) {
                _this9.get('logger').error('Problem in removeOfferIfNotQualified(). userId=' + Ember.Application.user.id, {
                    originalError: error
                });
            });
        },
        verifyCreditCardSave: function verifyCreditCardSave(paymentMethods, newCard) {
            // TODO: The API needs refactored here to report an actual CC add failure. Afterwards, this can be removed.
            if (!paymentMethods.find(function (method) {
                return method.get('id') === newCard.get('id');
            })) {
                // in the event of a failure when verifying the credit card, the credit card model state needs to be refresh.
                // the model needs to be refreshed because ember data now thinks it is an existing record rather than a new record to-be-recreated.
                var refreshedModel = this.getNewCreditCard();

                newCard.eachAttribute(function (attrName) {
                    return refreshedModel.set(attrName, newCard.get(attrName));
                });
                this.set('creditCard', refreshedModel);

                throw 'Credit Card Save Verification Failed: A credit card was not found on the user\'s account (UserId=' + Ember.Application.user.id + ')';
            }
        }
    });
});