define('ingenio-web/components/myaccount/advisor-feedback-grid', ['exports', 'moment', 'lodash', 'ingenio-web/utils/customer-utils'], function (exports, _moment, _lodash, _customerUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    gridApi: null,
    gridColumnApi: null,
    busy: Ember.inject.service('busy'),
    notifications: Ember.inject.service('toast'),
    mixpanelClient: Ember.inject.service('mixpanel-client'),
    query: 'query($advisorId:Int $listingId:Int $sortBy:[Sort]$pageSize:Int $pageNumber:Int){ratingsAndReviews(advisorId:$advisorId listingId:$listingId sortBy:$sortBy pageSize:$pageSize pageNumber:$pageNumber){averageRating totalEdges edges{node{rating review date target{type source{id sessionExists masterTransactionId customer{id userName nickname}listing{id category{id name}advisor{id userName}}}}}}}}',

    didInsertElement: function didInsertElement() {
      this.set('gridOptions.parent', this);
      this.set('register-as', this);
    },

    gridOptions: {
      pagination: true,
      paginationPageSize: 25,
      suppressPaginationPanel: true,
      gridAutoHeight: true,
      enableColResize: true,
      enableSorting: true,
      suppressCellSelection: true,
      suppressClickEdit: true,
      multiSortKey: 'ctrl',
      sortingOrder: ['desc', 'asc'],
      rowSelection: 'multiple',
      rowGroupPanelShow: 'never',

      parent: null,
      icons: {
        sortAscending: '<i class="grid-sort-icon fa fa-caret-up"/>',
        sortDescending: '<i class="grid-sort-icon fa fa-caret-down"/>'
      },

      columnDefs: [{
        headerName: 'Customer',
        headerClass: 'col-header-name',
        field: 'target.source.customer.userName',
        width: 180,
        //headerCheckboxSelection: true,
        //checkboxSelection: true,
        pinned: true,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return null;
          }

          var url = '#/myaccount/customers/' + params.data.target.source.customer.id;
          return '<a class="customer-link" href=\'' + url + '\' data-object=\'' + JSON.stringify(params.data) + '\'>' + params.value + '</a>';
        }
      }, {
        headerName: 'Nickname',
        field: 'target.source.customer.nickname',
        width: 150,
        suppressMenu: true
      }, {
        headerName: 'Rating Date',
        headerClass: 'col-header-rating-date',
        field: 'date',
        width: 120,
        sort: 'desc',
        suppressMenu: true,
        suppressSizeToFit: true,
        valueFormatter: function valueFormatter(params) {
          return (0, _moment.default)(params.value).format('MM/DD/YYYY');
        }
      }, {
        headerName: 'Listing Category',
        headerClass: 'col-header-listing-category',
        field: 'target.source.listing.category.name',
        suppressMenu: true
      }, {
        headerName: 'Rating',
        headerClass: 'col-header-rating',
        field: 'rating',
        width: 120,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) return null;

          var width = params.value / 5 * 100;
          return '<div class="granular-star-rating-container"><div class="star-rating rating-xss" style="font-size:16px"><div class="rating-container rating-gly-star" data-content="\uE006\uE006\uE006\uE006\uE006"><div class="rating-stars" data-content="\uE006\uE006\uE006\uE006\uE006" style="width: ' + width + '%;"></div></div></div> ';
        }
      }, {
        headerName: 'Comments',
        headerClass: 'col-header-comments',
        cellClass: 'cell-wrap-text',
        field: 'review',
        autoHeight: true,
        suppressMenu: true
      }, {
        headerName: 'Contact Type',
        headerClass: 'col-header-contact-type',
        field: 'target.type',
        width: 120,
        suppressMenu: true,
        cellRenderer: function cellRenderer(params) {
          if (!params.value) {
            return '';
          }

          var text = params.data.target.type;
          var atype = params.data.target.type.toUpperCase();
          switch (atype) {
            case 'CHAT':
              atype = 'CHATRECEIVED';
              text = 'Chat';
              break;
            case 'CALL':
              atype = 'CALLLIVERECEIVED';
              text = 'Call';
              break;
            case 'MAIL':
              atype = 'MAILSENT';
              text = 'Mail';
              break;
          }

          var url = _customerUtils.default.getActivityUrl(params.data.target.source.customer.id, {
            type: atype,
            activityId: params.data.target.source.id,
            mailId: params.data.target.source.id,
            masterTransactionId: params.data.target.source.masterTransactionId,
            sessionExists: !!params.data.target.source.sessionExists
          });
          if (!url) {
            return text;
          }
          return '<a class="contact-type-link" href="' + url + '" data-object=\'' + JSON.stringify(params.data) + '\'>' + text + ' </a>';
        }
      }],

      onGridReady: function onGridReady(params) {
        if (this.parent && this.parent.onGridReady) {
          this.parent.onGridReady(params);
        }
      }
    },

    // bind all required events
    onGridReady: function onGridReady(params) {
      // get the current grids width and based on it define how columns are sized
      var gridWidth = this.get('element').offsetWidth;
      if (gridWidth > 500) {
        params.api.sizeColumnsToFit();
      }

      // control sort change
      params.api.addEventListener('sortChanged', this.onSortChanged.bind(this));

      // control row selection
      params.api.addEventListener('selectionChanged', this.onSelectionChanged.bind(this));

      setTimeout(function () {
        params.api.resetRowHeights();
      }, 500);

      // just in case store refs to grid and column api
      this.set('gridApi', params.api);
      this.set('gridColumnApi', params.columnApi);

      // get dats from server side
      this.refreshGrid();
    },

    // control grid pagination
    gridActualPageSizeChanged: function () {
      if (this.get('gridApi')) {
        this.get('gridApi').paginationSetPageSize(this.get('pagination.actualPageSize'));

        if (this.get('pagination.actualPageSize') == 0) {
          this.get('gridApi').showNoRowsOverlay();
        }
      }
    }.observes('pagination.totalRecords', 'pagination.actualPageSize'),

    gridPaginationChanged: function () {
      Ember.run.once(this, 'refreshGrid');
    }.observes('pagination.currentPage', 'pagination.pageSize'),

    filtrationChanged: function () {
      if (this.get('pagination.currentPage') != 1) {
        this.get('pagination').set('currentPage', 1);
      } else {
        Ember.run.once(this, 'refreshGrid');
      }
    }.observes('filtration.selectedListingId'),

    // control grid row selection
    onSelectionChanged: function onSelectionChanged() {
      var _this = this;

      if (!this.get('selectedRows')) return;

      this.get('selectedRows').clear();

      var gridSelectedRows = this.get('gridApi').getSelectedRows();
      gridSelectedRows.forEach(function (selectedRow) {
        _this.get('selectedRows').addObject(selectedRow);
      });
    },

    // handle grid sorting
    onSortChanged: function onSortChanged(params) {
      var sortModel = params.api.getSortModel();
      var sorting = '';
      sortModel.forEach(function (item) {
        if (sorting === '') sorting = item.colId + ' ' + item.sort;else sorting = sorting + ', ' + item.colId + ' ' + item.sort;
      });

      this.refreshGrid();
    },

    // retreive grid data
    refreshGrid: function refreshGrid() {
      var _this2 = this;

      var gridApi = this.get('gridApi');

      this.get("busy").show();
      gridApi.deselectAll();
      gridApi.hideOverlay();

      var sorting = null;
      sorting = gridApi.getSortModel().map(function (item) {
        return {
          field: item.colId,
          order: item.sort == "desc" ? "DESCENDING" : "ASCENDING"
        };
      });

      //gridApi.setRowData(feedbackData);
      //this.get('busy').hide();

      var listingId = this.get('filtration.selectedListingId') == 0 ? null : this.get('filtration.selectedListingId');

      return Ember.RSVP.hash({
        graphQLResult: Ember.$.ajax(Ember.ENV.getApiRoot() + '/graphql2', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            query: this.get('query'),
            variables: {
              listingId: listingId,
              advisorId: Ember.Application.user.id,
              pageNumber: this.get('pagination.currentPage'),
              pageSize: this.get('pagination.pageSize'),
              sortBy: sorting
            }
          })
        })
      }).then(function (result) {
        var feedbackData = result.graphQLResult.data.ratingsAndReviews;

        _this2.get('pagination').set('totalRecords', feedbackData.totalEdges);
        gridApi.setRowData(feedbackData.edges.map(function (edge) {
          return edge.node;
        }));

        setTimeout(function () {
          gridApi.resetRowHeights();
        }, 500);
      }, function () {
        _this2.get('notifications').error('Error getting data from server', 'Customers Grid', {
          positionClass: 'toast-top-right'
        });
      }).finally(function () {
        _this2.get('busy').hide();
      });
    }
  });
});