define('ingenio-web/components/footer/acquisition-footer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isLoggedIn: Ember.computed('Ember.Application.user', 'Ember.Application.user.id', function () {
            return Ember.Application.user && Ember.Application.user.id && Ember.Application.user.id > 0;
        }),
        isAdvisor: Ember.computed('Ember.Application.user', function () {
            return Ember.Application.user.isAdvisor;
        }),
        currentYear: function () {
            var theDate = new Date();

            return theDate.getFullYear();
        }.property()
    });
});