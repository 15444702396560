define('ingenio-web/models/listing-draft', ['exports', 'ingenio-web/models/base/listing-base', 'ember-data/attr'], function (exports, _listingBase, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _listingBase.default.extend({
        categoryId: (0, _attr.default)('number', { defaultValue: 17005 }),
        minuteRate: (0, _attr.default)('number', { defaultValue: 4.99 }),
        requiredFieldsToSave: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return ['title', 'advisorName', 'minuteRate'];
            }
        }),

        canSaveDraft: function () {
            var _this = this;

            // check that listing has all required fields to be saved as draft
            // and the listing is dirty
            var valid = true;
            this.get('requiredFieldsToSave').forEach(function (field) {
                valid = valid && !_this.hasError(field);
            });

            return valid && this.get('isDirty');
        }.property('validationErrors.length', 'isDirty'),

        disableSaveDraft: function () {
            return !this.get('canSaveDraft');
        }.property('canSaveDraft')

    });
});