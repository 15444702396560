define('ingenio-web/components/arrange-conversation/actions-bar/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['checkout-order-summary-return-container'],

        additionalFundsAmountRounded: Ember.computed('additionalFundsAmount', function () {
            var additionalFundsAmount = this.get('additionalFundsAmount');

            if (!additionalFundsAmount || isNaN(additionalFundsAmount)) {
                return '0.00';
            }

            return Number(additionalFundsAmount).toFixed(2);
        }),

        balanceAmountRounded: Ember.computed('balanceAmount', function () {
            if (!this.get('balanceAmount')) {
                return '0.00';
            }

            return this.get('balanceAmount').toFixed(2);
        }),

        offerQualifierAmountRounded: Ember.computed('offerQualifierAmount', function () {
            return this.get('offerQualifierAmount') ? Number(this.get('offerQualifierAmount')).toFixed(2) : '0.00';
        }),

        totalAmountDue: Ember.computed('offerQualifierAmountRounded', 'additionalFundsAmountRounded', function () {
            var offerQualifierAmount = this.get('offerQualifierAmountRounded');
            var additionalFundsAmount = this.get('additionalFundsAmountRounded');

            var totalAmount = 0.00;

            if (offerQualifierAmount) {
                totalAmount += Number(offerQualifierAmount);
            }

            if (additionalFundsAmount) {
                totalAmount += Number(additionalFundsAmount);
            }

            return totalAmount.toFixed(2);
        }),

        talkTimeMinutes: Ember.computed('listingPricePerMinute', 'additionalFundsAmountRounded', 'specialOffer.rewardMinutes', 'specialOffer.rewardMoney', 'balance.monetaryAsNumber', 'balance.callMinutes', 'balance.sellerMinutes', function () {
            var additionalFundsAmount = Number(this.get('additionalFundsAmountRounded') || 0);
            var listingPricePerMinute = Number(this.get('listingPricePerMinute') || 0.1);
            var balanceAmount = Number(this.get('balance.monetaryAsNumber') || 0);

            var offerMinutes = Number(this.get('specialOffer.rewardMinutes') || 0);
            var offerAmount = Number(this.get('specialOffer.rewardMoney') || 0);

            var sellerMinutes = Number(this.get('balance.sellerMinutes') || 0);

            var callMinutes = Number(this.get('balance.callMinutes') || 0);
            if (callMinutes == 0) {
                var mins = (balanceAmount + offerAmount + additionalFundsAmount) / listingPricePerMinute + offerMinutes + sellerMinutes;
                callMinutes = Math.floor(mins);
            } else {
                // minutes available from the system and advisor offers
                var extraMinutes = callMinutes - Math.floor(balanceAmount / listingPricePerMinute);
                var _mins = (balanceAmount + additionalFundsAmount + offerAmount) / listingPricePerMinute + extraMinutes;
                callMinutes = Math.floor(_mins);
            }

            return callMinutes;
        })
    });
});