define('ingenio-web/components/footer/minimal-footer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var theDate = new Date();

            this.$('#currentYear').text(theDate.getFullYear());
        }
    });
});