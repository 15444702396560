define('ingenio-web/components/chat/chat-ended', ['exports', 'ingenio-web/mixins/end-chat-reason-helpers'], function (exports, _endChatReasonHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _Ember$Component$exte;

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Component.extend(_endChatReasonHelpers.default, (_Ember$Component$exte = {
        mixpanelClient: Ember.inject.service('mixpanel-client'),
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            var lastEnteredText = this.get('lastEnteredText');

            if (lastEnteredText && lastEnteredText != '') {
                //set a cookie containing the last entered text which is unsent.
                var sessionId = this.get('sessionId');
                this.get('cookie').setCookie('chat-unsent-message-' + sessionId, lastEnteredText, { path: '/', expires: 60 / 1440 }).then(function () {
                    return console.info('chat-unsent-message-cookie saved');
                });

                //Hide 'Send as Mail' link after 1 hour
                setTimeout(function () {
                    _this.set("hideSendAsMailLink", true);
                }, 1000 * 60 * 60);
                // To register trackLinks, wait for the link to be available in the DOM
                setTimeout(function () {
                    _this.get('mixpanelClient').trackLinksNew('#send-as-mail-link', 'Draft Chat Message Sent As Mail', {});
                }, 1000);
            }
        },

        actions: {
            leaveFeedback: function leaveFeedback() {
                var listingId = this.get('listingId');
                var sessionId = this.get('sessionId');
                var p = 'serviceId=' + listingId + '&activityId=' + sessionId + '&Type=4&TAF=1&pmode=0&ReturnUrl=&CancelUrl=';

                window.location.href = '/Feedback/UserFeedback?' + p;
            },
            dismissFeedback: function dismissFeedback() {
                this.set('feedbackDismissed', true);
            },
            onFeedbackSubmission: function onFeedbackSubmission() {
                this.set('feedbackSubmitted', true);
            }
        },

        isKeen: function () {
            return Ember.ENV.domainId === 1;
        }.property(),

        canLeaveFeedbackObserver: function () {
            var canLeaveFeedback = this.get('canLeaveFeedback');

            if (canLeaveFeedback) {
                var sessionId = this.get('sessionId');

                //TODO: find out do we still use this cookie?
                document.cookie = 'OutstandingFeedback=ActivityId=' + sessionId + '&ActivityType=chat&Shown=true; path=/; max-age=86400';
            }
        }.observes('canLeaveFeedback', 'sessionId'),

        viewTranscriptUrl: function () {
            var sessionId = this.get('sessionId');

            return '/app/#/chat/details/' + sessionId;
        }.property('sessionId'),

        browseCategoryUrl: function () {
            var categoryId = this.get('categoryId');

            return '/listings?category=' + categoryId;
        }.property('categoryId'),

        advisorCarouselUrl: function () {
            var categoryId = this.get('categoryId');
            var chatAvailability = 3;
            var listingId = this.get('listingId');

            return '/advisor-carousel?categoryId=' + categoryId + '&availability=' + chatAvailability + '&excludeListingId=' + listingId;
        }.property('categoryId', 'listingId')

    }, _defineProperty(_Ember$Component$exte, 'isKeen', function () {
        return Ember.ENV.domainId === 1;
    }.property()), _defineProperty(_Ember$Component$exte, 'isNewUser', function () {
        return this.get('firstBilledMinuteDate') === null;
    }.property('firstBilledMinuteDate')), _defineProperty(_Ember$Component$exte, 'feedbackDismissed', false), _defineProperty(_Ember$Component$exte, 'feedbackSubmitted', false), _defineProperty(_Ember$Component$exte, 'hideSendAsMailLink', false), _Ember$Component$exte));
});