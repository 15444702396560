define('ingenio-web/components/header/full-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isLoggedIn: Ember.computed('Ember.Application.user', 'Ember.Application.user.id', function () {
            return Ember.Application.user && Ember.Application.user.id && Ember.Application.user.id > 0;
        }),
        isAdvisor: Ember.computed('Ember.Application.user', function () {
            return Ember.Application.user.isAdvisor;
        }),
        userName: 'Anonymous Stranger',
        searchBoxOpen: false,
        showHeaderSearchContent: false,
        changeSearchIcon: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.errors = [];

            this.set('userName', Ember.Application.user.username);
        },
        doSearch: function doSearch(searchTerm) {
            var st = searchTerm;

            if (st === '') {
                st = '*';
            }
            window.location.href = '/search?st=' + st;
        },


        actions: {
            signIn: function signIn() {
                window.location.href = '/Registration/LoginStarter.aspx?Mode=StartWithLogin&returnurl=/myaccountredirect';
            },
            register: function register() {
                window.location.href = '/Registration/LoginStarter.aspx?Mode=StartWithReg&returnurl=/myaccountredirect';
            },
            openHeaderMobileMenu: function openHeaderMobileMenu() {
                // Ember.$ is equivalent to this.$ (this in this scope is not the component itself, but the element which called the action).
                // https://stackoverflow.com/questions/47368614/ember-js-this-not-working-on-component
                Ember.$('#hamburger-container').toggleClass('expanded');
                Ember.$('#mobile-menu-container').slideToggle('slow', function () {
                    if (Ember.$(this).is(':hidden')) {
                        Ember.$('.collapse').collapse('hide');
                    }
                });

                //Note: do not remove, will be used soon
                //this.$("#mobile-menu-container").on("show.bs.collapse", ".collapse", function () {
                //    this.$("#mobile-menu-container").find(".collapse.in").collapse("hide");
                //});
            },
            headerSearchIconClicked: function headerSearchIconClicked() {
                var _this = this;

                if (this.get('searchBoxOpen')) {
                    var searchTerm = Ember.$('#header-input-search').val().trim();

                    this.doSearch(searchTerm);
                } else {
                    //note: we need to add slider functionality here, same as on non-ember pages
                    this.set('showHeaderSearchContent', true);
                    Ember.run.later(this, function () {
                        _this.set('changeSearchIcon', true);
                        Ember.$('#header-input-search').focus();
                    }, 300);
                    this.set('searchBoxOpen', true);
                }
            },
            closeSearchPopover: function closeSearchPopover() {
                var _this2 = this;

                this.set('showHeaderSearchContent', false);
                Ember.run.later(this, function () {
                    _this2.set('changeSearchIcon', false);
                }, 300);
                Ember.$('#header-input-search').val('');
                this.set('searchBoxOpen', false);
            },
            mobileHeaderSearchIconClicked: function mobileHeaderSearchIconClicked() {
                var searchTerm = Ember.$('#mobile-header-input-search').val().trim();

                this.doSearch(searchTerm);
            }
        }
    });
});