define('ingenio-web/router', ['exports', 'ingenio-web/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType
    });

    Router.map(function () {

        this.route('error');

        this.route('listings', {
            resetNamespace: true,
            path: '/listings'
        }, function () {
            this.route('new');
            this.route('idv');
        });

        //Chat related routes here
        this.route('chat', function () {
            this.route('initiate', {
                queryParams: ['listingid']
            });

            this.route('consumerSession', {
                path: '/sessions/:session_id/consumer'
            });

            this.route('advisorSession', {
                path: '/sessions/:session_id/advisor'
            });

            this.route('voiceToTextTest', {
                path: '/voice-to-text-test'
            });

            this.route('session', {
                path: '/sessions/:session_id'
            });

            this.route('chatDetails', {
                path: '/details/:session_id'
            });
        });

        this.route('mail', function () {
            this.route('details', {
                path: '/details/:mail_id'
            });
        });

        this.route('add-money');

        //Call related routes here
        this.route('call', function () {
            this.route('callVerification', {
                path: 'verification'
            });

            this.route('status', {
                queryParams: ['callid', 'listingid']
            });

            this.route('callDetails', {
                path: '/details/:call_id'
            });
        });

        //Listing page related routes
        this.route('listing/edit', {
            path: '/listings/:listing_id/edit'
        });
        this.route('listing/drafts', {
            resetNamespace: true,
            path: '/listings/drafts'
        });
        this.route('listing/draft', {
            resetNamespace: true,
            path: '/listings/drafts/:listing_draft_id'
        });

        this.route('call-setup', function () {
            this.route('details');
            this.route('payment-details');
            this.route('confirmation');
        });

        this.route('chat-setup', function () {
            this.route('details');
            this.route('payment-details');
            this.route('confirmation');
            this.route('availability-error');
        });

        this.route('add-funds', function () {
            this.route('details');
            this.route('payment-details');
            this.route('success');
        });

        this.route('redeem-offer', function () {
            this.route('details');
            this.route('payment-details');
            this.route('success');
            this.route('no-offer');
        });

        this.route('offer/RedeemSuccess', {
            path: '/offer/redeem-success/:offer_id'
        });

        this.route('not-authorized');
        this.route('no-customer-data-yet');

        this.route('monitor', {
            path: '/monitor'
        });

        this.route('smsNotifications', {
            path: '/sms-notifications'
        });

        this.route('offer/NotQualified', {
            path: '/offer/not-qualified/:offer_id'
        });

        //wildcard router to catch all missing URLs and redirect to 404, should be defined last
        this.route('not-found', {
            path: '/*path'
        });

        this.route('dev');

        this.route('myaccount', function () {
            this.route('listings');
            this.route('feedback');
            this.route('tax-documents');
            this.route('customers');
            this.route('customer-details', {
                path: 'customers/:id'
            });
        });

        this.route('privacy', function () {
            this.route('do-not-sell-my-info');
        });

        this.route('default-payment-method');
    });

    exports.default = Router;
});