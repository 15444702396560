define('ingenio-web/components/category-selector', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        setup: function () {
            this.selectedFirstLevelCategoryId = 17004;
            this.selectedSecondLevelCategoryId = 0;
            this.selectedThirdLevelCategoryId = 0;
            this.set('initialCategoryDropDownDefaults', {
                firstLevelCategoryId: null,
                secondLevelCategoryId: null,
                thirdLevelCategoryId: null
            });
            this.getSelectedCategoryForDifferentLevels();

            this.addObserver('secondLevelCategoryList.[]', this, 'secondLevelCategoryListObserver');
            this.addObserver('selectedFirstLevelCategoryId', this, 'secondLevelCategoryListObserver');

            this.addObserver('thirdLevelCategoryList.[]', this, 'thirdLevelCategoryListObserver');
            this.addObserver('selectedSecondLevelCategoryId', this, 'thirdLevelCategoryListObserver');

            this.addObserver('selectedThirdLevelCategoryId', this, 'updateSelectedCategoryIdOnThirdLevelChange');
        }.on('init'),

        selectedCategoryName: function () {
            var selectedCategory = this.get('categoryList').findBy('id', this.get('selectedCategoryId'));

            return selectedCategory.name;
        }.property('categoryList', 'selectedCategoryId'),

        firstLevelCategoryList: function () {
            return this.get('categoryList') ? this.get('categoryList').filterBy('parentId', 1) : [];
        }.property('categoryList'),

        /*   Whenever there is a change in value at the 1st level category this method will be kicked in. Does the following.
            1) Based on the selected 1st level category value, filters the category list to be filled in the 2nd level
            2) Sets the selected 2nd level category value which should be set once the 2nd level categories are populated */
        secondLevelCategoryList: function () {
            var selectedFirstLevelCategoryId = parseInt(this.get('selectedFirstLevelCategoryId'));
            var secondLevelCategories = this.get('categoryList') ? this.get('categoryList').filterBy('parentId', selectedFirstLevelCategoryId) : [];

            return secondLevelCategories;
        }.property('selectedFirstLevelCategoryId', 'categoryList'),

        secondLevelCategoryListObserver: function secondLevelCategoryListObserver() {
            var selectedFirstLevelCategoryId = parseInt(this.get('selectedFirstLevelCategoryId'));
            var secondLevelCategories = this.get('secondLevelCategoryList');

            var firstLevelCategoryId = this.get('initialCategoryDropDownDefaults').firstLevelCategoryId;

            if (secondLevelCategories.length > 0) {
                if (firstLevelCategoryId && selectedFirstLevelCategoryId !== firstLevelCategoryId) {
                    //If the parent category selection has changed from the initial state, default to the first item in the list
                    this.set('selectedSecondLevelCategoryId', secondLevelCategories[0].id);
                }
            }

            //if nothing selected, preselect first one
            if (!this.get('selectedSecondLevelCategoryId') && secondLevelCategories[0]) {
                this.set('selectedSecondLevelCategoryId', secondLevelCategories[0].id);
            }
        }, //.observes('secondLevelCategoryList.[]', 'selectedFirstLevelCategoryId'),

        /*  Whenever there is a change in value at the 2nd level category this method will be kicked in. Does the following
            1) Based on the selected 2nd level category value, filters the category list to be filled in the 3rd level
            2) Sets the  selected 2nd level category value to the model
            3) Sets the selected 3rd level category value which should be set once the 3rd level categories are populated */
        thirdLevelCategoryList: function () {
            var selectedSecondLevelCategoryId = parseInt(this.get('selectedSecondLevelCategoryId'));
            var thirdLevelCategories = this.get('categoryList') ? this.get('categoryList').filterBy('parentId', selectedSecondLevelCategoryId) : [];

            return thirdLevelCategories;
        }.property('selectedSecondLevelCategoryId', 'categoryList'),

        thirdLevelCategoryListObserver: function thirdLevelCategoryListObserver() {
            var selectedSecondLevelCategoryId = parseInt(this.get('selectedSecondLevelCategoryId'));
            var thirdLevelCategories = this.get('thirdLevelCategoryList');

            //Set the selectedCategory to the model
            if (selectedSecondLevelCategoryId > 0) {
                this.set('selectedCategoryId', selectedSecondLevelCategoryId); //Set the model.categoryId
            }

            var secondLevelCategoryId = this.get('initialCategoryDropDownDefaults').secondLevelCategoryId;

            if (thirdLevelCategories.length > 0) {
                if (secondLevelCategoryId && selectedSecondLevelCategoryId !== secondLevelCategoryId) {
                    //If the parent category selection has changed from the initial state, default to the first item in the list
                    this.set('selectedThirdLevelCategoryId', thirdLevelCategories[0].id);
                }
            }
        }, //.observes('thirdLevelCategoryList.[]', 'selectedSecondLevelCategoryId'),

        getSelectedCategoryForDifferentLevels: function getSelectedCategoryForDifferentLevels() {
            /*  Since the model's categoryId represents the leaf node, we need to find all its parents above to populate the drop-downs
                representing different levels
                Note: Because we currently have only 2 levels of nesting, we don't need to go recursively. */

            if (this.get('selectedCategoryId')) {
                //Do all these discovery only if the model has a default categoryId

                //we start with assumption that the model.cateogryId is at second level
                var firstLevelCategoryId = 17004;
                var secondLevelCategoryId = this.get('selectedCategoryId');
                var thirdLevelCategoryId = 0;
                var secondLevelCategory = this.get('categoryList').findBy('id', secondLevelCategoryId);

                if (secondLevelCategory && secondLevelCategory.parentId > 0) {
                    firstLevelCategoryId = secondLevelCategory.parentId;

                    var firstLevelCategory = this.get('categoryList').findBy('id', firstLevelCategoryId);

                    if (firstLevelCategory && firstLevelCategory.parentId && firstLevelCategory.parentId !== 1) {
                        // get additional category and shift existing categories down by one
                        var topCategory = this.get('categoryList').findBy('id', firstLevelCategory.parentId);

                        if (topCategory) {
                            thirdLevelCategoryId = secondLevelCategoryId;
                            secondLevelCategoryId = firstLevelCategoryId;
                            firstLevelCategoryId = topCategory.id;
                        }
                    }
                }

                //Now set the default values for all the drop-downs
                this.set('selectedFirstLevelCategoryId', firstLevelCategoryId);
                this.set('selectedSecondLevelCategoryId', secondLevelCategoryId);
                this.set('selectedThirdLevelCategoryId', thirdLevelCategoryId);
                this.set('initialCategoryDropDownDefaults', {
                    firstLevelCategoryId: this.get('selectedFirstLevelCategoryId'),
                    secondLevelCategoryId: this.get('selectedSecondLevelCategoryId'),
                    thirdLevelCategoryId: this.get('selectedThirdLevelCategoryId')
                });
            }
        },

        //On change of dropdown value on 3rd level category, update the selectedCategoryId
        updateSelectedCategoryIdOnThirdLevelChange: function updateSelectedCategoryIdOnThirdLevelChange() {
            if (this.get('selectedThirdLevelCategoryId') > 0) {
                this.set('selectedCategoryId', this.get('selectedThirdLevelCategoryId')); //Set the model.categoryId
            }
        } //.observes('selectedThirdLevelCategoryId')
    });
});